//
// Card stats
//

.card-stats {
	.card-body {
		padding: 1rem 1.5rem;
	}

	.card-status-bullet {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
	}
}
