#root {
	height: 100%;
}

// Delete Groups Page from SideBar
.navbar-nav {
	.nav-item:nth-child(8) {
		display: none;
	}

	.nav-item:nth-child(9) {
		display: none;
	}

	.nav-item:nth-child(10) {
		display: none;
	}
}

.react-bootstrap-table-pagination-total {
	display: none;
}

// Modal
.fade {
	transition: opacity 0.02s linear !important;
}

.modal.fade .modal-dialog {
	transition: transform 0.1s ease-out !important;
}

// control the modal fade-in-down modal speed
.fade.show .modal-dialog {
	animation-duration: 0.5s !important;
}

// Input Search
.select2-container--default .select2-search--dropdown .select2-search__field {
	padding: 0px 0.5rem !important;
	font-size: 0.8rem !important;
	line-height: 0.5 !important;
}

// Pagination
.page-item.active .page-link {
	background-color: #00bfa5 !important;
	border-color: #00bfa5 !important;
}
