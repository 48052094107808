//
// Brand buttons
//

// Color variations

@each $color, $value in $brand-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}
