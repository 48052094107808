//
// Mask
//

.mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include transition($transition-base);
}

// Backdrop

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 1040; // navbar fixed has a z-index of 1030
}

.backdrop-dark {
	background: rgba($black, 0.3);
}

.backdrop-light {
	background: rgba($white, 0.3);
}
