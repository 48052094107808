//
// Navbar top (Topbar)
//

.navbar-top {
	border: 0;
	padding-left: 1rem;
	padding-right: 1rem;

	.container,
	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}

	@include media-breakpoint-up(sm) {
		.navbar-brand {
			display: none;
		}
	}

	@include media-breakpoint-down(xs) {
		.navbar-collapse {
			width: 100%;
		}

		.nav-item {
			position: static;

			.dropdown-menu {
				position: absolute;
				width: 94%;
				min-width: auto;
				left: 3%;
				right: auto;
			}
		}
	}

	&.border-bottom {
		&.navbar-dark {
			border-color: rgba(255, 255, 255, 0.08) !important;
		}

		&.navbar-light {
			border-color: rgba(0, 0, 0, 0.04) !important;
		}
	}
}

.sidenav-pinned {
	.navbar-top {
		.navbar-brand {
			display: none;
		}
	}
}
