//
// Lavalamp
//

/*!
 * Lavalamp
 * http://lavalamp.magicmediamuse.com/
 */

.lavalamp {
	position: relative;
}
.lavalamp-item {
	z-index: 5;
	position: relative;
}
.lavalamp-object {
	position: absolute;
	top: 0;
	left: 0;
}

/* Custom easing transitions */

.lavalamp .lavalamp-object {
	transition-property: transform, width, height;
}
.lavalamp .lavalamp-object.ease {
	transition-timing-function: ease;
}
.lavalamp .lavalamp-object.ease-in {
	transition-timing-function: ease-in;
}
.lavalamp .lavalamp-object.ease-out {
	transition-timing-function: ease-out;
}
.lavalamp .lavalamp-object.ease-in-out {
	transition-timing-function: ease-in-out;
}
.lavalamp .lavalamp-object.linear {
	transition-timing-function: linear;
}
.lavalamp .lavalamp-object.easeInQuad {
	transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.lavalamp .lavalamp-object.easeInCubic {
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.lavalamp .lavalamp-object.easeInQuart {
	transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.lavalamp .lavalamp-object.easeInQuint {
	transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.lavalamp .lavalamp-object.easeInSine {
	transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
.lavalamp .lavalamp-object.easeInExpo {
	transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.lavalamp .lavalamp-object.easeInCirc {
	transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.lavalamp .lavalamp-object.easeInBack {
	transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.lavalamp .lavalamp-object.easeOutQuad {
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.lavalamp .lavalamp-object.easeOutCubic {
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lavalamp .lavalamp-object.easeOutQuart {
	-webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
	-moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.lavalamp .lavalamp-object.easeOutQuint {
	-webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.lavalamp .lavalamp-object.easeOutSine {
	-webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	-moz-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	-o-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.lavalamp .lavalamp-object.easeOutExpo {
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	-moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	-o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.lavalamp .lavalamp-object.easeOutCirc {
	-webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
	-moz-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
	-o-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
	transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.lavalamp .lavalamp-object.easeOutBack {
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.lavalamp .lavalamp-object.easeInOutQuad {
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	-moz-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	-o-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.lavalamp .lavalamp-object.easeInOutCubic {
	-webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.lavalamp .lavalamp-object.easeInOutQuart {
	-webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.lavalamp .lavalamp-object.easeInOutQuint {
	-webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	-moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	-o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.lavalamp .lavalamp-object.easeInOutSine {
	-webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	-moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	-o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.lavalamp .lavalamp-object.easeInOutExpo {
	-webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
	-moz-transition-timing-function: cubic-bezier(1, 0, 0, 1);
	-o-transition-timing-function: cubic-bezier(1, 0, 0, 1);
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
}
.lavalamp .lavalamp-object.easeInOutCirc {
	-webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.lavalamp .lavalamp-object.easeInOutBack {
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	-moz-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	-o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
