//
// Badge floating
//

.btn {
	.badge-floating {
		position: absolute;
		top: -50%;
		transform: translateY(50%);
		border: 3px solid;

		&.badge:not(.badge-circle) {
			transform: translate(147%, 50%);
		}
	}
}
