//
// Floating navbar
//

.navbar-floating-wrapper {
	padding-top: 1rem;
	padding-bottom: 1rem;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1;

	.navbar {
		border-radius: $border-radius;
	}
}
