//
// Checklist
// works with the [data-toggle="checklist"] which inits the JS for item selection
//

// Checklist entry
// is used in JS to get each entry and verify if it checked

.checklist-entry {
}

// Checklist item
// contains the checklist entry info and checkbox

.checklist-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding-left: 0.75rem;

	&:before {
		content: '';
		position: absolute;
		width: 3px;
		height: 100%;
		top: 0;
		left: 0;
		background-color: theme-color('primary');
		border-radius: 8px;
	}
}

.checklist-item-checked {
	.checklist-info * {
		text-decoration: line-through;
	}
}

// Color variations

@each $color, $value in $theme-colors {
	.checklist-item-#{$color} {
		&:before {
			background-color: theme-color($color);
		}
	}
}
