//
// Opacity
// modify the transparency of an element with this quick modifier classes
//

.opacity-1 {
	opacity: 0.1 !important;
}
.opacity-2 {
	opacity: 0.2 !important;
}
.opacity-3 {
	opacity: 0.3 !important;
}
.opacity-4 {
	opacity: 0.4 !important;
}
.opacity-5 {
	opacity: 0.5 !important;
}
.opacity-6 {
	opacity: 0.6 !important;
}
.opacity-7 {
	opacity: 0.7 !important;
}
.opacity-8 {
	opacity: 0.8 !important;
}
.opacity-8 {
	opacity: 0.9 !important;
}
.opacity-10 {
	opacity: 1 !important;
}
