//
// Money card
// A visual representation of a credit/debit card
//

.card-serial-number {
	display: flex;
	justify-content: space-between;
	font-size: $h1-font-size;

	> div:not(:last-child) {
		display: flex;
		flex: 1 1 auto;

		&:after {
			content: '-';
			flex: 1 1 auto;
			text-align: center;
			position: relative;
			left: -2px;
		}
	}

	@include media-breakpoint-down(xs) {
		font-size: $h3-font-size;
	}
}
