// Animation Input Placeholder
.divFormInput {
	position: relative;

	.fieldError {
		border-color: rgb(255, 132, 132) !important;
	}
}

.divFormInput label {
	position: absolute;
	// top: 13px;
	display: inline-block;
	pointer-events: none;

	margin-left: 10px;

	font-size: 0.9rem;

	transition: all 0.3s;
}

.divFormInput input {
	~ label {
		top: -12px;
		padding: 0px 5px;
		border-radius: 0.3rem;
		background-color: #fff;
	}

	&:disabled {
		background-color: #eee !important;
	}

	&::placeholder {
		color: #fff;
	}

	&:focus {
		border-color: #aaa;
	}

	// &:focus,
	// &:not(:placeholder-shown),
	// &:valid {
	//   background-color: #fff;
	//   ~ label {
	//     top: -12px;
	//     background-color: #fff;
	//     border-radius: 15px;
	//     padding: 0px 5px;
	//   }

	//   // Remove placeholder
	//   &::placeholder {
	//     color: #fff;
	//   }
	// }
}

// Span error under Input
.divFormInput .spanError {
	color: rgb(255, 132, 132);
	font-size: 14px;
}

// .divFormInput input:disabled {
//   ~ label {
//     top: -12px;
//     background-color: #fff;
//     border-radius: 0.3rem;
//     padding: 0px 5px;
//   }
// }

// Separator Box Login
.separatorLogin {
	display: flex;
	align-items: center;

	hr {
		flex: 1;
		height: 0;
	}

	span {
		font-size: 14px;
		margin: 0 8px;
	}
}
