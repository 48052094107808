//
// Article
//

article {
	h4:not(:first-child),
	h5:not(:first-child) {
		margin-top: 3rem;
	}

	h4,
	h5 {
		margin-bottom: 1.5rem;
	}

	figure {
		margin: 3rem 0;
	}

	h5 + figure {
		margin-top: 0;
	}
}
