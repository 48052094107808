//
// Icon buttons
//

.btn-icon {
	.btn-inner--icon {
		img {
			width: 20px;
		}
	}

	.btn-inner--text:not(:first-child) {
		margin-left: 0.75em;
	}

	.btn-inner--text:not(:last-child) {
		margin-right: 0.75em;
	}
}

// Button only with icon and NO text

.btn-icon-only {
	width: 2.375rem;
	height: 2.375rem;
	padding: 0;
}

a.btn-icon-only {
	line-height: 2.5;
}

.btn-icon-only.btn-sm {
	width: 2rem;
	height: 2rem;
}

//
// Clipboard button
// dedicated element for copying icons
//

.btn-icon-clipboard {
	margin: 0;
	padding: 1.5rem;
	font-size: $font-size-base;
	font-weight: $font-weight-normal;
	line-height: 1.25;
	color: $gray-800;
	background-color: $gray-100;
	border-radius: $border-radius;
	border: 0;
	text-align: left;
	font-family: inherit;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	-moz-appearance: none;
	cursor: pointer;
	width: 100%;
	margin: 0.5rem 0;

	&:hover {
		background-color: $white;
		box-shadow:
			rgba(0, 0, 0, 0.1) 0 0 0 1px,
			rgba(0, 0, 0, 0.1) 0 4px 16px;
	}

	> div {
		align-items: center;
		display: flex;
	}

	i {
		box-sizing: content-box;
		color: theme-color('primary');
		vertical-align: middle;
		font-size: 1.5rem;
	}

	span {
		display: inline-block;
		font-size: 0.875rem;
		line-height: 1.5;
		margin-left: 16px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: middle;
	}
}
