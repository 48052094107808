//
// Footer
//

// General styles

.footer {
	padding: $footer-padding-y $footer-padding-x;

	.col-footer {
		.heading {
			color: $footer-heading-color;
			letter-spacing: 0;
			font-size: $footer-heading-font-size;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			margin-bottom: 1rem;
		}
	}

	.nav .nav-item .nav-link,
	.footer-link {
		color: $footer-link-color !important;

		&:hover {
			color: $footer-link-hover-color !important;
		}
	}

	.list-unstyled li a {
		display: inline-block;
		padding: 0.125rem 0;
		color: $footer-link-color;
		font-size: $footer-link-font-size;

		&:hover {
			color: $footer-link-hover-color;
		}
	}

	.copyright {
		font-size: $font-size-sm;
	}
}

// Dark footer

.footer-dark {
	.col-footer .heading {
		color: $white;
	}
}

// Footer nav used for copyright and some links, but not limited to this

.nav-footer {
	.nav-link {
		font-size: $font-size-sm;
	}

	.nav-item:last-child {
		.nav-link {
			padding-right: 0;
		}
	}
}

// Footer with cards over

.footer.has-cards {
	overflow: hidden;
	padding-top: 500px;
	margin-top: -420px;
	position: relative;
	background: transparent;
	pointer-events: none;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 600px;
		height: 2000px;
		background: theme-color('secondary');
		transform: skew(0, -8deg);
	}

	.container {
		pointer-events: auto;
		position: relative;
	}
}

// Position the footer at the bottom of the page if the body is smaller than the window

.footer-auto-bottom {
	position: absolute;
	bottom: 0;
	width: 100%;
}
