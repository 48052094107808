//
// Navabar collapse
//

// Collapse

.navbar-collapse-header {
	display: none;
}

@keyframes show-navbar-collapse {
	0% {
		opacity: 0;
		transform: scale(0.95);
		transform-origin: 100% 0;
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes hide-navbar-collapse {
	from {
		opacity: 1;
		transform: scale(1);
		transform-origin: 100% 0;
	}

	to {
		opacity: 0;
		transform: scale(0.95);
	}
}
